<template>
  <div>
    <v-banner
        class="lavBanner" 
        single-line
        height="400"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/AISolution-banner.png"
          height="100%"
          class="d-flex align-center"
        >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex justify-center">
          <v-card color="transparent" flat width="80%">
            <div class="d-flex flex-no-wrap justify-center" style="color: #fff">
              <div>
                <v-card-title class="white--text font-size-banner-title font-weight-bold px-0">
                  源于实践的行业方案，赋能产业AI升级
                </v-card-title>
                <!-- <v-card-title class="white--text font-size-banner-tip px-0" style="line-height:30px;margin:0 auto;text-align: center;display: block;">
                  推动物理世界的全面数字化转型
                </v-card-title>
                <div class="d-flex justify-center mt-5">
                  <v-btn
                    width="160"
                    class="font-size-20 rounded-pill"
                    color="#0084ff"
                    style="color: #fff"
                  >
                    立即体验
                  </v-btn>
                </div> -->
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <v-container class="containerStyle max-width-1200" height='400' >
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg" >
          <img src="https://h5.ophyer.cn/official_website/other/AISolution1.png"/>
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">智能教育</v-card-title>
            <v-card-text  class="con_text pa-0">基于语音交互、人脸与人体识别、文字识别等多项AI技术，赋能软硬件教学产品，实现更好的人机交互体验；同事打造智慧校园，实现校园安全、校内考勤等关键场景升级，提升校园安全
              和体验，降低管理成本
            </v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution2.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >智能政务</v-card-title>
            <v-card-text class="con_text pa-0">融合语音技术、人脸识别、文字识别等多项AI技术，应用到智慧城市、政府办公、信息管理和公共服务等场景中，助力政务决策、业务流程优化，提升利企便民服务体验</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution3.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">智能医疗</v-card-title>
            <v-card-text  class="con_text pa-0">基于灵医智惠技术中台能力，构造临床辅助决策系统、眼底影像分析系统、医疗大数据整体解决方案、智能诊前助手、慢病管理平台等产品系列，服务院内院外全场景</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution4.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >企业服务</v-card-title>
            <v-card-text class="con_text pa-0">将人脸识别、文字识别、语音交互等多项AI能力应用于电销、客服、语音质检、协同办公、员工考勤、单据识别等业务场景，提高企业内部管理效率</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution5.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">智能零售</v-card-title>
            <v-card-text  class="con_text pa-0">围绕快速消费品企业在线下渠道中的销量逻辑，提供基于AI技术的数字化访销解决方案，对访销过程的精细化管理，通过提升一线业务人员人效，最终实现销量的增长</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution6.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >工业制造业</v-card-title>
            <v-card-text class="con_text pa-0">基于人脸、图像、PaddlePaddle、EasyDL等技术，针对工厂质检、仓储物流、智能监控等场景，提供物流安全、生产线质检、园区管理等场景方案，全面提升安全生产管理能力</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex justify-center" flat>
        <div class="pr-10 borderimg">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution7.png" />
        </div>
        <div class="text-wrap" style="padding-right:160px">
          <v-card class="pa-0" flat>
            <v-card-title  class="con_tit pa-0 mb-5">智能园区</v-card-title>
            <v-card-text  class="con_text pa-0">基于人脸识别等技术，针对社区、楼宇等地产环境，提供人员/车辆通行管理、安防布控、智慧服务、无人作业车、智慧家居等场景方案，全面提升管理效率与安全等级</v-card-text>
          </v-card>
        </div>
      </v-card>
      <v-card class="wrap part-one d-flex flex-no-wrap justify-center flex-row-reverse" flat>
        <div class="pl-10 borderimg_two">
          <img src="https://h5.ophyer.cn/official_website/other/AISolution8.png" />
        </div>
        <div class="text-wrap" style="padding-left:160px">
          <v-card class="pa-0"  flat>
            <v-card-title class="con_tit pa-0 mb-5" >信息服务</v-card-title>
            <v-card-text class="con_text pa-0">在信息处理场景下，提供各种技术接口整合的语音搜索、图像搜索、智能录入、口碑分析、有声阅读、内容审核、内容分类等信息服务解决方案，解放人力，降低成本，提升产品竞争力</v-card-text>
          </v-card>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import FanDate from '../../static/FanData.js'
export default {
  name: "AIsolution",
  template:FanDate.AI.template

}
</script>

<style scoped lang='scss'>
.lavBanner {
  ::v-deep .v-banner__wrapper,
  ::v-deep .v-banner__content,
  ::v-deep .v-banner__text {
    padding: 0 !important;
    height: 100%;
  }
}
.public-title{
  height: 0;
  margin: 65px auto 65px;
}
.banner_title{
  font-size: 60px;
  //font-family:$bold-font-family;
  color: #FFFFFF !important;
  // padding-top: 21%;
}
.scence_text{
  color: #FFFFFF !important;
  line-height: 26px !important;
}
.banner_span{
  //font-family: $normal-font-family;
  color: #2672FF !important;
  font-size: 20px;
}
::v-deep .v-banner__wrapper{
  padding: 0 !important;
}
.first_card{
  margin-top: 80px;
}
.containerStyle{
  margin-top: 80px;
  .v-card__title{
    line-height: 26px !important;
  }
  .borderimg{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: right;
    }
  }
  .borderimg_two{
    width:600px;
    height:300px;
    img{
      border-radius: 10px;
      float: left;
    }
  }
  .part-one{
    margin-bottom: 21px;
    .text-wrap{
      width: 600px;
      .con_tit{
        font-size: 24px;
        font-weight: bold;
        color: #333333;
        line-height: 30px;
        margin-top:84px
      }
      .con_text{
        height: 40px;
        font-size: 14px;
        font-weight: 400;
        color: #666;
        line-height: 26px;
      }
      .banner-btn {
        margin-top: 50px;
        width: 160px;
        height: 50px;
        background-color: #0568FD;
        border-radius: 25px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}
.titleh1{
  font-size: 80px;
  //font-family: $bold-font-family;
  line-height: 26px !important;
}
.spantitle{
  font-size: 24px;
  //font-family: $body-font-family;
  line-height: 26px !important;
}
.suntitle{
  font-size: 30px;
  //font-family: $bold-font-family;
  // font-weight: bold;
  font-style: italic;
  line-height: 48px !important;
}
.ptitle{
  font-size: 14px;
  font-family: $body-font-family;
  // font-weight: 400;
  line-height: 26px !important;
}

::v-deep .v-carousel__controls{
  display: none !important;
}

</style>